export const startData = {
  startList: [
    { name: "文件ID", text: "fileId" },
    { name: "报告名称", text: "name" },
    { name: "报告类型", text: "fileTypeName" },
    { name: "报告状态", text: "fileStateName" },
    { name: "报告上传者", text: "userName" },
    { name: "上传时间", text: "date" },
  ],
  currentPage: 1,
  pageSize: 10,
  total: 0,
  loading: true,
  spreadTable: [],
  tableHeight: 400,
  operation: ["查看"],
  pageType: 1,
  lastState: 1000,
  filetype: null,
  showHtml: false,
  reportHtml: "",
  financeRadio: "证券",
  disabled: false,
  courseType: 0,
  showHtmlTitle: "",
  reportList:[]
};